
import { projectFirestore } from "../../../../../firebase/config"



const save_data_from_excel_in_db = async(data) => {
    const promises = []
    for(const item of data){
        const doc = projectFirestore.collection('Count_catalog').doc()
        promises.push(doc.set({
            ...item,
            uid:doc.id
        },{merge:true})) 
    }

    await Promise.all(promises)
}
const save_single_item_in_db = async(item) => {
        const doc = projectFirestore.collection('Count_catalog').doc()
        await doc.set({
            ...item,
            uid:doc.id
        },{merge:true})  
}

const save_edited_single_item_in_db = async(item) => {
    await projectFirestore.collection('Count_catalog').doc(item.uid)
    .set({
        ...item
    },{merge:true})
}

const get_data_from_db = async() => {
    const docs = await projectFirestore.collection('Count_catalog').get()
    return docs.docs.map(doc=>doc.data())
}

const check_if_item_exist_in_db = async(item) => {
    const doc = await projectFirestore.collection('Count_catalog').where('פריט','==',item['פריט']).get()
    return doc.empty
}

const delete_item_from_db = async(item) => {
    await projectFirestore.collection('Count_catalog').doc(item.uid).delete()
}



export{
    save_data_from_excel_in_db,
    get_data_from_db,
    save_single_item_in_db,
    check_if_item_exist_in_db,
    delete_item_from_db,
    save_edited_single_item_in_db
}